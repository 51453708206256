import gql from "graphql-tag";
import { OutsideBook, OutsideBookCreateInput, OutsideBookEdgesResponse, OutsideBookResponse, OutsideBookSearchInput, OutsideBookUpdateInput } from "../gen/types";
import { ResponseGql } from "../types/MDOTypes";
import { BaseService } from "./BaseService";

export class OutsideBookService extends BaseService {

  constructor() {
    super();
  }

  get(id: string): ResponseGql<OutsideBook> {
    const query = gql`query outsideBook($id: ID!) {
      outsideBook(id: $id) {
        id
        name
        description
        enable
        qrCode
        linkShare {
          link
          image
        }
        qtScanned
        qtExamDone
        qtAbandoned
        campaign {
          id
          name
        }
        account {
          id
          name
        }
        agent {
          id
          name
        }
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { id: id } })
    .then(result => result.data.outsideBook);
  }

  getForEdit(id: string): ResponseGql<OutsideBook> {
    const query = gql`query outsideBook($id: ID!) {
      outsideBook(id: $id) {
        id
        name
        description
        enable
        campaign {
          id
        }
        agent {
          id
        }
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { id: id } })
    .then(result => result.data.outsideBook);
  }

  getAll(outsideBookSearch: OutsideBookSearchInput): ResponseGql<OutsideBookEdgesResponse> {
    const query = gql`query outsideBooks($outsideBookSearch: OutsideBookSearchInput) {
      outsideBooks(params: $outsideBookSearch) {
        edges {
          id
          name
          description
          enable
          qrCode {
            link
            image
          }
          linkShare
          qtScanned
          qtExamDone
          qtAbandoned
          campaign {
            name
          }
          account {
            name
          }
          agent {
            name
          }
        }
        pagination {
          total
          page
        }
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { outsideBookSearch: outsideBookSearch } }).then(result => {
      return result.data.outsideBooks;
    });
  }

  create(outsideBook: OutsideBookCreateInput): ResponseGql<OutsideBookResponse> {
    const mutation = gql`mutation createOutsideBook($outsideBook: OutsideBookCreateInput!) {
      createOutsideBook(input: $outsideBook) {
        data {
          id
        }
        message
      }
    }`;

    return this.apolloClient.mutate({ mutation: mutation, variables: { outsideBook: outsideBook }})
    .then((result) => result.data.createOutsideBook);
  }

  update(id: string, outsideBook: OutsideBookUpdateInput): ResponseGql<OutsideBookResponse> {
    const mutation = gql`mutation updateOutsideBook($id: ID!, $outsideBook: OutsideBookUpdateInput!) {
      updateOutsideBook(id: $id, input: $outsideBook) {
        data {
          id
        }
        message
      }
    }`;

    return this.apolloClient.mutate({ mutation: mutation, variables: { id: id, outsideBook: outsideBook }})
    .then((result) => result.data.updateOutsideBook);
  }

  remove(id: string): ResponseGql<OutsideBookResponse> {
    const mutation = gql`mutation removeOutsideBook($id: ID!) {
      removeOutsideBook(id: $id) {
        data {
          id
        }
        message
      }
    }`;

    return this.apolloClient.mutate({ mutation: mutation, variables: { id: id }})
    .then((result) => result.data.removeOutsideBook);
  }

}